<template>
    <p v-if="status === 'ok'">200 OK</p>
    <p v-else-if="status === 'fail'">Failed</p>
    <p v-else>Processing</p>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  name: "UploadStd",
  data() {
    return {
      status: 'processing',
      studentsList: [
        '司徒悅怡',
        '司徒翰翔',
        '布逸榛',
        '伍璟熙',
        '吳展同',
        '李清揚',
        '李靖瑜',
        '杜芷瑩',
        '沈澄',
        '冼嘉俊',
        '卓子傑',
        'Jackson 柯展偉班長',
        '洪宏樂',
        '胡匡政',
        '胡旨峻',
        '唐穎謙',
        '徐子聰',
        '殷子軒',
        '秦大成',
        '張曉婷',
        '梁裕騫',
        '陳崇正',
        '馮穎',
        '黃天恩',
        '黃建中',
        '黃家峻',
        'Tiffany 黃曼晴班長',
        '楊皓勤',
        '溫晉樂',
        '農皓燊',
        '潘正揚',
        '潘焯琳',
        '潘樂謙',
        '鄧伊淑',
        '鄧志軒',
        'Edward 鄧浩峰主席',
        '鄭慧臻',
        '戴恩霖',
      ],
    };
  },
  mounted() {
      console.log(this.studentsList);
        firebase.firestore().collection('tools').doc('namelists').set({
            nameChi: this.studentsList,
        },{
          merge: true
        }).then(() => {
            this.status = 'ok'
        }).catch((err) => {
            this.status = 'fail'
            console.log(err);
        })
  },
};
</script>

<style>
</style>