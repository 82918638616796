import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    classroomModeStatus: false,
  },
  mutations: {
    updateClassroomModeStatus(state, updatedStatus) { 
      state.classroomModeStatus = updatedStatus; 
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
