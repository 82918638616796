<template>
  <!-- <div class="w-full h-full bg-white" id="safeArea2"></div> -->
  <Navbar />
   <TransitionRoot appear :show="updateToV2" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-14 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="
                  inline-block
                  w-full
                  max-w-md
                  p-6
                  my-8
                  overflow-hidden
                  text-left
                  align-middle
                  transition-all
                  transform
                  bg-white
                  shadow-2xl
                  rounded-2xl
                "
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium text-center leading-6 text-black"
                >
                  提示
                </DialogTitle>
                <div class="m-2">
                  <p class="text-lg text-center text-black">Quahk S6E 已推出2.0版本！新功能包括：</p>
                  <p class="text-sm text-center text-black">1. 全新界面</p>
                  <p class="text-sm text-center text-black">2. 隨時查詢距離「Last Day」還有多少天</p>
                  <p class="text-sm text-center text-black">3. 可以看到班級一路以來的合照</p>
                  <p class="text-sm text-center text-black">4. 隨時看到每位同學的同學錄</p>
                  <p class="text-sm text-center text-black">還有更多... 立即點擊「升級」開始體驗！</p>
                </div>

                <div class="mt-4 flex items-center justify-evenly">
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-blue-900
                      bg-blue-100
                      border border-transparent
                      rounded-md
                      hover:bg-blue-200
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    onclick="window.location.href = 'https://s6e.quahk.com'"
                  >
                    升級
                  </button>
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-blue-900
                      bg-blue-100
                      border border-transparent
                      rounded-md
                      hover:bg-blue-200
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    @click="this.updateToV2 = false;"
                  >
                    以後再說
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot> 
  <router-view></router-view>
  <!-- <transition :name="transitionName">
      <component :is="Component"></component>
    </transition> -->
</template>

<script>
import Navbar from "./components/Navbar.vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

export default {
  components: {
    Navbar,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  data() {
    return {
     updateToV2: true
    };
  },

  methods: {
    checkTime() {
      let d = new Date();
      let h = d.getHours();
      let m = d.getMinutes();
      if (m < 10) {
        //let timeNow = h + '0' + m;
        let timeNow = `${h}0${m}`;
        //console.log(timeNow)
        return timeNow;
      } else {
        let timeNow = `${h}${m}`;
        //console.log(timeNow)
        return timeNow;
      }
    },
    checkTodayDate() {
      const d = new Date();
      let day = d.getDate();
      let month = d.getMonth();
      const year = d.getFullYear();
      const weekday = d.getDay();
      if (month + 1 < 10) {
        month = `0${month + 1}`;
      } else {
        month = `${month + 1}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      const todayDate = `${year}${month}${day}`;
      return [todayDate, weekday];
    },
    checkTmrDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let day = tomorrow.getDate();
      let month = tomorrow.getMonth();
      const year = tomorrow.getFullYear();
      const weekday = tomorrow.getDay();
      if (month + 1 < 10) {
        month = `0${month + 1}`;
      } else {
        month = `${month + 1}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      const tomorrowDate = `${year}${month}${day}`;
      return [tomorrowDate, weekday];
    },
  },
  mounted() {
    // console.log(this.updateNotice)
    
  }
};
</script>
