<template>
  <div
    class="
      flex flex-col
      items-center
      shadow-2xl
      mt-10
      mb-8
      mx-7
      p-12
      rounded-3xl
      space-y-6
    "
  >
    <p class="text-3xl sm:text-4xl text-center">熱烈歡迎</p>
    <p class="text-3xl rainbow-text text-center sm:text-3xl">{{ activeLesson }} {{ teacher }}</p>
    <p class="text-3xl text-center sm:text-3xl">{{ mode }}</p>
  </div>
</template>

<script>
import firebase from "firebase/app";
import 'firebase/firestore'

export default {
  name: "greetingsPanel",
  data() {
    return {
      activeLesson: "眼前的你",
      teacher: "來到Quahk S6E",
      mode: '！課室模式已啟動！'
    };
  },
  mounted() {
    let [currentDate, currentWeekday] = this.$root.checkTodayDate();
    currentDate = Number(currentDate);
    const timeNow = this.$root.checkTime();
    let lessonPlanTeacherRef = "";
    //let lessonPlan = [];
    switch (currentWeekday) {
      case 1:
        lessonPlanTeacherRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("monday");
        break;

      case 2:
        lessonPlanTeacherRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("tuesday");
        break;

      case 3:
        lessonPlanTeacherRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("wednesday");
        break;

      case 4:
        lessonPlanTeacherRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("thursday");
        break;

      case 5:
        lessonPlanTeacherRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("friday");
        break;

      case 6:
        lessonPlanTeacherRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("saturday");
        break;

      default:
        break;
    }

    if (currentWeekday !== 0) {
      lessonPlanTeacherRef.onSnapshot((doc) => {
      let lessonPlanTeacher = doc.data().teachers;
      let lessonPlan = doc.data().period;

      if ((timeNow >= 745) & (timeNow <= 850)) {
        this.activeLesson = lessonPlan[1];
        this.teacher = lessonPlanTeacher[1];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 855) & (timeNow <= 944)) {
        this.activeLesson = lessonPlan[2];
        this.teacher = lessonPlanTeacher[2];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 945) & (timeNow <= 1034)) {
        this.activeLesson = lessonPlan[3];
        this.teacher = lessonPlanTeacher[3];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 1035) & (timeNow <= 1125)) {
        this.activeLesson = lessonPlan[4];
        this.teacher = lessonPlanTeacher[4];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 1130) & (timeNow <= 1220)) {
        this.activeLesson = lessonPlan[5];
        this.teacher = lessonPlanTeacher[5];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 1345) & (timeNow <= 1459)) {
        this.activeLesson = lessonPlan[6];
        this.teacher = lessonPlanTeacher[6];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 1500) & (timeNow <= 1549)) {
        this.activeLesson = lessonPlan[7];
        this.teacher = lessonPlanTeacher[7];
        this.mode = '使用本班電腦瀏覽器！';
      } else if ((timeNow >= 1550) & (timeNow <= 1640)) {
        this.activeLesson = lessonPlan[8];
        this.teacher = lessonPlanTeacher[8];
        this.mode = '使用本班電腦瀏覽器！';
      } else {
      }
    });
    }
    
  },
};
</script>

<style>
</style>