<template>
  <div class="md:container mb-8 mx-7 rounded-3xl md:mx-auto mt-10 shadow-2xl">
    <div
      class="
        flex flex-row
        justify-start
        px-10
        md:px-20
        pt-20
        pb-10
        space-x-8
        md:space-x-28
        items-center
      "
    >
      <router-link tag="button" :to="{ name: 'Home' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </router-link>
      <p class="text-xl">個人資料 | Profile</p>
    </div>

    <hr class="h-1 bg-gray-300 text-gray-300" />

    <div v-if="status === 'ok'">
      <div class="p-8" v-for="user in users" :key="user">
        <div class="flex flex-col items-center justify-center">
          <div
            class="
              mb-6
              p-1
              rounded-full
              bg-gradient-to-b
              from-pink-500
              via-red-500
              to-yellow-500
            "
          >
            <div class="p-1 bg-white rounded-full">
              <img
                v-bind:src="user.photoURL"
                alt="User Photo"
                class="rounded-full"
              />
            </div>
          </div>
          <p class="text-2xl rainbow-text font-bold">{{ user.displayName }}</p>
          <p class="text-gray-500 mb-6">{{ user.realIdentity }}</p>
          <p class="text-xl">電郵地址</p>
          <p class="text-gray-500 mb-6">{{ user.email }}</p>
          <p class="text-xl">電話號碼</p>
          <p class="text-gray-500">{{ user.phoneNum ? user.phoneNum : '未綁定' }}</p>
          <router-link tag="a" :to="{ name: 'PhoneReg' }" class="text-blue-500 underline ">
            {{ !user.phoneNum ? '點我去綁定！' : '修改/取消綁定' }}
          </router-link>
        </div>
      </div>
    </div>

    <div v-else-if="status === 'notLogin'">
      <StatusText content="請先登入" />
    </div>

    <div v-else>
      
        <StatusText content="資料載入中" />
      
    </div>
  </div>
</template>

<script>
import StatusText from "../components/StatusText.vue";
import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/firestore'

export default {
  name: "ProfilePage",
  components: {
    StatusText,
  },
  data() {
    return {
      users: [],
      status: "loading",
    };
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let userInfo = [];
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .onSnapshot((doc) => {
            userInfo.push({ ...doc.data(), id: doc.id });
            this.users = userInfo;
            this.status = "ok";
          });
      } else {
        this.status = "notLogin";
      }
    });
  },
};
</script>

<style>
</style>