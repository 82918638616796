<template>
<div
      class="
        flex flex-row
        justify-start
        px-10
        md:px-20
        pt-20
        items-center
      "
    >
      <button
        v-on:click="this.$router.go(-1);"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </button>
      <a class="text-xl font-productSans ml-4" v-on:click='this.$router.go(-1);'>返回上一頁</a>
      
    </div>
<div class="m-8">
    <p class="text-3xl">Quahk S6E</p>
    <p class="text-3xl">隱私權保護政策</p><br/>
    <p>此版本之隱私權保護政策自二零二二年二月二十五日生效。</p><br/>
  <p>
      非常歡迎您選擇使用「Quahk S6E」（以下簡稱本系統），為了讓您能夠安心的使用本系統的各項服務與資訊，特此向您說明本系統的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
  </p><br/>
  <p class="text-2xl">一、隱私權保護政策的適用範圍</p><br/>
  <p>隱私權保護政策內容，包括本系統如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本系統以外的相關連結網站，也不適用於非本系統所委託或參與管理的人員。</p><br/>
  <p class="text-2xl">二、個人資料的蒐集、處理及利用方式</p><br/>
      <ul class="list-disc">
          <li>當您造訪本系統或使用本系統所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本系統不會將個人資料用於其他用途。</li>
          <li>本系統在您使用互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。</li>
          <li>於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。</li>
          <li>您可以隨時向我們提出請求，以更正或刪除本系統所蒐集您錯誤或不完整的個人資料，請見最下方聯繫管道。</li>
            <li>於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。</li>
        </ul><br/>
  <p class="text-2xl">三、資料之保護</p><br/>
    <ul class="list-disc"><li>本系統主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。</li>
    <li>如因業務需要有必要委託其他單位提供服務時，本系統亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。</li></ul><br/>
    <p class="text-2xl">
        四、網站對外的相關連結
    </p><br/>
    <p>本系統的網頁提供其他網站的網路連結，您也可經由本系統所提供的連結，點選進入其他網站。但該連結網站不適用本系統的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。</p><br/>
    <p class="text-2xl">五、與第三人共用個人資料之政策</p><br/>
    <p>本系統絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。</p><br/>
    <p>前項但書之情形包括不限於：</p>
    <ul class="list-disc">
        <li>經由您書面同意。</li>
        <li>法律明文規定。</li>
        <li>為免除您生命、身體、自由或財產上之危險。</li>
        <li>與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。</li>
        <li>當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
        <li>有利於您的權益。</li>
        <li>本系統委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。</li>
    </ul><br/>
    <p class="text-2xl">六、Cookie 之使用</p><br/>
    <p>為了提供您最佳的服務，本系統會在您的電腦中放置並取用我們的 Cookie，若您不願接受 Cookie 的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie 的寫入，但可能會導致網站某些功能無法正常執行 。</p><br/>
    <p class="text-2xl">七、隱私權保護政策之修正</p><br/>
    <p>本系統隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。</p><br/>
    <p class="text-2xl">八、聯繫管道</p><br/>
    <p>對於本站之隱私權政策有任何疑問，或者想提出變更、移除個人資料之請求，請Email至：support@quahk.com</p>
</div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>

<style>

</style>