<template>
  <button @click="broadcastAll()">click me</button>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

export default {
  name: "broadcastAll",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    broadcastAll() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          var broadcastAllApi = firebase
            .functions()
            .httpsCallable("broadcastAllApi");
          broadcastAllApi().then((result) => {
            console.log(result.data.checkStatus);
          });
        } else {
          console.log("you fool");
        }
      });
    },
  },
};
</script>

<style>
</style>