<template>
  <transition name="fade">
    <dutiesPanel v-if="showPanel" />
  </transition>
  <transition name="fade">
    <lessonPanel v-if="showLessonPanel" />
  </transition>
  <transition name="fade">
    <greetingsPanel v-if="showGreetingsPanel" />
  </transition>

  <div
    class="flex flex-row flex-wrap justify-evenly p-4"
    :class="
      showPanel || showLessonPanel || showGreetingsPanel || isInTransition
        ? ''
        : 'mt-10'
    "
  >
    <App
      appName="值日"
      destination="Duties"
      class="bg-gradient-to-l from-green-300 via-blue-500 to-purple-600"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-12 md:h-16 w-12 md:w-16"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
        />
      </svg>
    </App>
    <App
      appName="抽籤"
      destination="luckyDraw"
      class="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-12 w-12 md:w-16 md:h-16"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </App>
    <App
      appName="升學日程表"
      destination="Settings"
      class="bg-gradient-to-r from-red-500 to-red-800 hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 md:w-12 md:h-12"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path d="M12 14l9-5-9-5-9 5 9 5z" />
        <path
          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
        />
      </svg>
    </App>
    <App
      appName="座位表"
      destination="SeatPlan"
      class="hidden bg-gradient-to-r from-pink-400 to-pink-600"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-12 md:w-16 md:h-16 w-12"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
        />
      </svg>
    </App>
    <App
      appName="個人資料"
      destination="ProfilePage"
      class="bg-gradient-to-r from-green-500 to-green-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-12 md:w-16 w-12 md:h-16"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
        />
      </svg>
    </App>
    <App
      appName="設定"
      destination="Settings"
      class="md:hidden bg-gradient-to-r from-gray-700 via-gray-900 to-black"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-12 md:h-16 w-12 md:w-16"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    </App>
  </div>
</template>

<style>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all 1.5s ease;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 1.5s ease;
}
</style>
  
<script>
// @ is an alias to /src
import Navbar from "../components/Navbar.vue";
import App from "../components/App.vue";
import dutiesPanel from "../components/dutiesPanel.vue";
import lessonPanel from "../components/lessonPanel.vue";
import greetingsPanel from "../components/greetingsPanel.vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export default {
  name: "Home",
  components: {
    Navbar,
    App,
    dutiesPanel,
    lessonPanel,
    greetingsPanel,
  },
  data() {
    return {
      showPanel: false,
      showGreetingsPanel: false,
      showLessonPanel: false,
      isInTransition: false,
      classroomModeStatus: this.$store.state.classroomModeStatus,
      userLevel: "guest",
    };
  },
  async mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // this.userLevel = doc.
              this.userLevel = doc.data().group;
            }
          })
          .catch((error) => {
            //console.log("Error getting document:", error);
          });
      } else {
      }
    });
    //console.log(this.classroomMode)
    let [currentDate, currentWeekday] = this.$root.checkTodayDate();
    currentDate = Number(currentDate);
    const currentTime = this.$root.checkTime();

    if (this.classroomModeStatus === true) {
      this.isInTransition = true;
      await this.classroomMode();
    } else {
      if (currentTime >= 2100) {
        switch (currentWeekday) {
          case 6:
            this.showPanel = false;
            break;

          default:
            this.showPanel = true;
            break;
        }
      } else {
        switch (currentWeekday) {
          case 0:
            this.showPanel = false;
            break;

          default:
            this.showPanel = true;
            break;
        }
      }
    }
  },
  methods: {
    async classroomMode() {
      this.showPanel = false;
      this.showGreetingsPanel = true;

      await new Promise((res, rej) => {
        setTimeout(() => {
          this.showGreetingsPanel = false;
          res();
        }, 5000);
      });

      await new Promise((res, rej) => {
        setTimeout(() => {
          this.showLessonPanel = true;
          res();
        }, 1500);
      });

      await new Promise((res, rej) => {
        setTimeout(() => {
          this.showLessonPanel = false;
          res();
        }, 5000);
      });

      await new Promise((res, rej) => {
        setTimeout(() => {
          this.showPanel = true;
          res();
        }, 1500);
      });

      await new Promise((res, rej) => {
        setTimeout(() => {
          this.showPanel = false;
          res();
        }, 5000);
      });

      await new Promise((res, rej) => {
        setTimeout(() => {
          this.classroomMode();
          res();
        }, 1500);
      });
    },
  },
};
</script>
