<template>
  <div
    class="
      md:container
      mb-8
      mx-7
      rounded-3xl
      md:mx-auto
      mt-10
      shadow-2xl
      font-productSans
    "
  >
    <div
      class="
        flex flex-row
        justify-start
        px-10
        md:px-20
        pt-20
        pb-10
        space-x-8
        md:space-x-28
        items-center
      "
    >
      <router-link tag="button" :to="{ name: 'Home' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </router-link>
      <p class="text-xl">班費 | Class Fee Management</p>
      <router-link tag="button" :to="{ name: 'DutiesRegistration' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </router-link>
    </div>
    <hr class="h-1 bg-gray-300 text-gray-300" />
    <div class="py-6 flex flex-col justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-24 w-24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="gold"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <p class="text-2xl pt-4">本班班費餘額</p>
      <p class="text-3xl pt-1 font-bold">MOP 10</p>
    </div>
    <!-- <div>
      <input type="radio" name="tab" class="hidden" id="income" checked />
      <input
        type="radio"
        name="tab"
        class="hidden"
        id="expenditure"
        v-model="expenditure"
      />
    </div> -->
    <!-- <div class="container mx-auto mt-4 pb-4 flex justify-evenly items-center">
      <label for="income">
        <span
          class="py-4 px-10 text-center rounded-full inline-block bg-gray-200"
          >收入</span
        >
      </label>
      <label for="expenditure">
        <span class="py-4 px-10 text-center rounded-full inline-block"
          >開支</span
        >
      </label>
    </div> -->
  </div>
  <div
    class="
      md:container
      mb-8
      mx-7
      pb-2
      rounded-3xl
      md:mx-auto
      shadow-2xl
      font-productSans
    "
  >
    <TabGroup>
      <TabList class="flex m-4 p-2 space-x-1 bg-gray-200 rounded-xl">
        <Tab v-slot="{ selected }" as="template">
          <button
            class="w-full rounded-lg py-2.5"
            :class="selected ? 'bg-green-500 text-white' : 'text-black'"
          >
            收入
          </button></Tab
        >
        <Tab v-slot="{ selected }" as="template">
          <button
            class="w-full rounded-lg py-2.5"
            :class="selected ? 'bg-red-500 text-white' : 'text-black'"
          >
            支出
          </button></Tab
        >
      </TabList>
      <TabPanels>
        <TabPanel>
          <div class="m-4 p-4 bg-gray-200 flex flex-row justify-around shadow-lg rounded-xl">
            <div>
              <p class="text-3xl">20211010</p>
              <p class="text-xl text-gray-400">Initial Commit</p>
            </div>
            <div>
              <p class="text-3xl text-green-600">+50</p>

            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div class="m-4 p-4 bg-gray-200 flex flex-row justify-around shadow-lg rounded-xl">
            <div>
              <p class="text-3xl">20211110</p>
              <p class="text-xl text-gray-400">Initial Commit</p>
            </div>
            <div>
              <p class="text-3xl text-red-600">-50</p>

            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

export default {
  name: "ClassFee",
  data() {
    return {
      income: true,
      expenditure: false,
    };
  },
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
};
</script>

<style>
</style>