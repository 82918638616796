<template>
  <div
    class="
      flex flex-col
      items-center
      shadow-2xl
      mt-10
      mb-8
      mx-7
      p-12
      rounded-3xl
      space-y-6
    "
  >
    <p class="text-4xl rainbow-text font-bold sm:text-4xl">實時上課時間表</p>
    <p class="text-3xl hidden sm:text-3xl">上一課節：{{ previousLesson }}</p>
    <p class="text-3xl sm:text-3xl">現時課節：{{ activeLesson }}</p>
    <p class="text-3xl sm:text-3xl">下一課節：{{ nextLesson }}</p>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore'

export default {
  name: "lessonPanel",
  data() {
    return {
      previousLesson: "Loading...",
      activeLesson: "Loading...",
      nextLesson: "Loading...",
    };
  },
  mounted() {
    let [currentDate, currentWeekday] = this.$root.checkTodayDate();
    currentDate = Number(currentDate);
    const timeNow = this.$root.checkTime();
    let lessonPlanRef = "";
    //let lessonPlan = [];
    switch (currentWeekday) {
      case 1:
        console.log('logic working')
        lessonPlanRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("monday");
        break;

       case 2:
          lessonPlanRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("tuesday");
        break;

      case 3:
        lessonPlanRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("wednesday");
        break;

      case 4:
        lessonPlanRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("thursday");
        break;

      case 5:
        lessonPlanRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("friday");
        break;

      case 6:
        lessonPlanRef = firebase
          .firestore()
          .collection("lessonPlan")
          .doc("saturday");
        break;

      default:
        this.previousLesson = '--';
        this.activeLesson = '--';
        this.nextLesson = '--';
        break;
    }

    if (currentWeekday !== 0) {
      // console.log('hello')
      lessonPlanRef.onSnapshot((doc) => {
      let lessonPlan = doc.data().period;
      // console.log(doc.data())

      if ((timeNow >= 745) & (timeNow <= 850)) {
        this.previousLesson = lessonPlan[0];
        this.activeLesson = lessonPlan[1];
        this.nextLesson = lessonPlan[2];
      } else if ((timeNow >= 855) & (timeNow <= 944)) {
        this.previousLesson = lessonPlan[1];
        this.activeLesson = lessonPlan[2];
        this.nextLesson = lessonPlan[3];
      } else if ((timeNow >= 945) & (timeNow <= 1034)) {
        this.previousLesson = lessonPlan[2];
        this.activeLesson = lessonPlan[3];
        this.nextLesson = lessonPlan[4];
      } else if ((timeNow >= 1035) & (timeNow <= 1125)) {
        this.previousLesson = lessonPlan[3];
        this.activeLesson = lessonPlan[4];
        this.nextLesson = lessonPlan[5];
      } else if ((timeNow >= 1130) & (timeNow <= 1220)) {
        this.previousLesson = lessonPlan[4];
        this.activeLesson = lessonPlan[5];
        this.nextLesson = lessonPlan[6];
      } else if ((timeNow >= 1345) & (timeNow <= 1459)) {
        this.previousLesson = lessonPlan[5];
        this.activeLesson = lessonPlan[6];
        this.nextLesson = lessonPlan[7];
      } else if ((timeNow >= 1500) & (timeNow <= 1549)) {
        this.previousLesson = lessonPlan[6];
        this.activeLesson = lessonPlan[7];
        this.nextLesson = lessonPlan[8];
      } else if ((timeNow >= 1550) & (timeNow <= 1640)) {
        this.previousLesson = lessonPlan[7];
        this.activeLesson = lessonPlan[8];
        this.nextLesson = lessonPlan[9];
      } else {
        this.previousLesson = "--";
        this.activeLesson = "--";
        this.nextLesson = "--";
      }
    });
    }
  },
};
</script>

<style>
</style>