<template>
  <tr class="w-full" v-for="row in rowData" :key="row">
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12">{{ row[0] }}</td>
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12">{{ row[1] }}</td>
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12">{{ row[2] }}</td>
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12">{{ row[3] }}</td>
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12">{{ row[4] }}</td>
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12">{{ row[5] }}</td>
    <td class="border-2 border-gray-300 py-6 px-6 w-1/12 break-words">{{ row[6] }}</td>
  </tr>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  name: "SeatPlanRow",
  emits: ['status'],
  data() {
    return {
      rowData: [],
    };
  },
  mounted() {
    firebase
      .firestore()
      .collection("tools")
      .doc("seatTable")
      .collection("rows")
      .orderBy("order", "desc")
      .get()
      .then((snapshot) => {
        let rowData = [];
        snapshot.forEach((doc) => {
          rowData.push({ ...doc.data().data, id: doc.id });
        //     console.log(rowData)
          for (let i = 0; i < rowData.length; i++) {
              for (let key in rowData[i]) {
                  rowData[i][key] = rowData[i][key].replaceAll("\\n", ` `);
                  //console.log(rowData[i][key])
              }
          }
        });

        this.rowData = rowData;
        //this.$emit('status', true)
      });
    //console.log(Number(test.replace( /^\D+/g, '')), test2.split())
  },
  computed: {
    jumpNextLine() {},
  },
};
</script>

<style>
</style>