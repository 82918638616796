<template>
  <div class="p-8 text-xl text-center">
    <p>{{ content }}</p>
  </div>
</template>

<script>
export default {
  name: "StatusText",
  props: ["content"],
};
</script>

<style>
</style>