import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import firebase from 'firebase/app';
import 'firebase/analytics';
import "firebase/performance";
import "firebase/app-check"
import store from './store'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyDlG2-ESHFcdwmGTnrX9Ioz-DbIazKY35I",
    authDomain: "quahk-s6e.firebaseapp.com",
    databaseURL: "https://quahk-s6e.firebaseio.com",
    projectId: "quahk-s6e",
    storageBucket: "quahk-s6e.appspot.com",
    messagingSenderId: "98530986080",
    appId: "1:98530986080:web:6f3438b86742007d055b05",
    measurementId: "G-19Z7W7ZTLX"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const perf = firebase.performance();
const analytics = firebase.analytics();
const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

appCheck.activate(
    '6LcUKdAfAAAAAHM78sZ_cHp2Z5scy7agMYUxmdpm',

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    true);
firebase.firestore();

createApp(App).use(store).use(router).mount('#app')
