<template>
  <router-link tag="button" :to="{ name: destination }"
    class="
      flex flex-col
      justify-center
      items-center
      space-y-2
      mx-2
      w-32
      h-32
      md:w-48
      md:h-48
      mb-6
      shadow-2xl
      rounded-3xl
      transition ease-in-out duration-500
      transform
      hover:scale-110
    "
  >
    <slot></slot>
    <p class="text-white md:text-2xl font-bold md:font-normal">{{ appName }}</p>
  </router-link>
</template>

<script>
export default {
  name: "App",
  props: ['appName', 'destination'],
};
</script>

<style>
</style>